<template>
    <div class="bg-white p-6 rounded-lg">
      <div class="block w-[200px] rounded-[5px] mb-3">
        <img
          class="rounded-[5px]"
          :src="
            item.image_url !== ''
              ? item.image_url
              : 'https://i0.wp.com/sunrisedaycamp.org/wp-content/uploads/2020/10/placeholder.png?ssl=1'
          "
          alt=""
        />
      </div>
      <span class="text-sm">
        {{ item.created_at }}
      </span>
  
      <h1 class="font-semibold text-xl">{{ item.title }}</h1>
      <p class="text-sm">{{ item.description }}</p>
      <h6 class="text-sm">Category: {{ item.category }}</h6>
    </div>
  </template>
  
  <script>
  export default {
    computed: {
      item() {
        return JSON.parse(localStorage.getItem("notification"));
      },
    },
  };
  </script>
  
  <style></style>