/* eslint-disable no-undef */
import Vue from "vue";
import $request from "@/https/axios";
// import config from "@/https//config.js";

import axios from "axios";

import router from "@/router";
const url = " https://backend.hycatrade.com/wp-json/nellalink/";

// Vue.use(require("vue-moment"));

const getDefaultState = () => {
  return {
    loading: false,
    error: false,
    success: false,
    regError: false,
    regSuccess: false,
    validationErrors: {},
    user: {},
    userMeta: {},
    wallet_address: {},
    loggedIn: false,
    loggedInAt: null,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    isLoading: (state) => state.loading,
    getUser: (state) => state.user,
    getLoggedInAt: (state) => state.loggedInAt,
    getUserMeta: (state) => state.userMeta,
    getWalletAddress: (state) => state.wallet_address,
  },

  mutations: {
    SET_LOADING(state) {
      state.loading = true;
      state.error = false;
      state.success = false;
      state.regError = false;
      state.regSuccess = false;
      state.validationErrors = {};
    },

    SET_ERROR(state, message) {
      state.error = message;
      state.success = false;
      state.loading = false;
      state.validationErrors = {};
    },

    SET_SUCCESS(state, message) {
      state.success = message;
      state.error = false;
      state.validationErrors = {};
      state.loading = false;
    },

    SET_VALIDATION_ERRORS(state, payload) {
      state.loading = false;
      state.validationErrors = payload;
    },

    REMOVE_ERROR_SUCCESS(state) {
      state.error = false;
      state.success = false;
      state.regError = false;
      state.regSuccess = false;
      state.validationErrors = {};
      state.loading = false;
    },

    LOGIN(state) {
      state.loading = false;
      state.loggedIn = true;
    },

    SET_USER(state, payload) {
      state.user = payload;
    },

    SET_LOGIN_TIME(state, payload) {
      state.loggedInAt = payload;
    },

    SET_USER_META(state, payload) {
      state.userMeta = payload;
      state.wallet_address = payload.eth_crypto_wallet_deposit_address
    },

    SET_WALLET_ADDRESS(state, payload) {
      state.wallet_address = payload;
    },

    RESET(state) {
      state.user = null;
      state.token = null;
      // router.push('/');
      localStorage.clear();
      localStorage.removeItem('hyca_token');
      // Object.keys(state).forEach((key) => {
      //   Object.assign(state[key], null);
      // });
      // Object.keys(state).forEach((key) => {
      //   Object.assign(state[key], null);
      // });
    },
  },
  actions: {
    // Login request
    async loginUser({ commit }, payload) {
      commit("SET_LOADING", true);
      try {
        let res = await $request.post(`users/login`, payload);
        let responsePayload = res.data;
        console.log(responsePayload);
        localStorage.setItem("hyca_token", responsePayload.data.access_token);
        Vue.$toast.open({
          message: `${responsePayload.message}`,
          type: "success",
          position: "top",
          // all of other options may go here
        });
        commit("LOGIN", {
          accessToken: responsePayload.access_token,
        });
        let time = new Date().toString();
        // let time = new Date()
        console.log(time);
        commit("SET_LOGIN_TIME", time);
        commit("SET_USER", responsePayload.data);
        // Check redirect URL
        const url = window.location.search;
        const params = new URLSearchParams(url);
        const d = params.get("redirectFrom");

        // dispatch("getUserMeta", responsePayload.data.user_id);

        router.push(d || "/");
        return res;
      } catch (error) {
        console.log(error);
        Vue.$toast.open({
          message: `Error!`,
          type: "error",
          position: "top",
          // all of other options may go here
        });
        if (error) {
          let errorPayload = error.data;
          if (errorPayload.message) {
            commit("SET_ERROR", errorPayload.message);
            if (errorPayload.error) {
              console.log(errorPayload.error);
              commit("SET_VALIDATION_ERRORS", errorPayload.error);
            }
            return;
          }
        }
        commit("SET_ERROR", "Internal connection error, please try again.");
        return error;
      }
      finally {
        commit("SET_LOADING", false);
      }
    },

    async getUserMeta({ commit }, payload) {
      try {
        let res = await axios.get(
          `${url}v2/smart-meta-manager/user/${payload}?meta_key=user_profile_photo_url,nll_user_email_address_verified,phone_number,rimplenet_referrer_sponsor,eth_crypto_wallet_deposit_address,user_initial_balance_locked,user_kyc_level,user_kyc_level_1_status,user_kyc_level_2_status,user_kyc_last_message`
        );
        console.log(res.data.data);
        let resPayload = res.data.data;
        commit("SET_USER_META", resPayload);
        return res;
      } catch (error) {
        return error.response;
      }
    },

    generateWalletAddress({ dispatch }, payload) {
      axios
        .get(`${process.env.VUE_APP_MIDDLEWARE_API_URL}cryptocurrency/bscscan/deposit-address/${payload}`)
        .then((res) => {
          dispatch("getUserMeta", payload);
          return res;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          return err;
        });
    },

    // Request request
    async registerUser({ commit }, payload) {
      commit("SET_LOADING", true);
      try {
        let res = await $request.post(`users/`, payload);
        commit("SET_SUCCESS", "User Registered");
        let responsePayload = res.data;
        Vue.$toast.open({
          message: `${responsePayload.message}`,
          type: "success",
          position: "top",
          // all of other options may go here
        });
        return res;
      } catch (error) {
        console.log(error);
        Vue.$toast.open({
          message: `Error!`,
          type: "error",
          position: "top",
          // all of other options may go here
        });
        if (error.data) {
          let errorPayload = error.data;
          if (errorPayload.message) {
            commit("SET_ERROR", errorPayload.message);
            if (errorPayload.error) {
              console.log(errorPayload.error);
              commit("SET_VALIDATION_ERRORS", errorPayload.error);
            }
            return;
          }
        }
        commit("SET_ERROR", "Internal connection error, please try again.");
        return error.data;
      }
    },

    // Logout Request
    logout({ commit }) {
      commit("RESET");
    },
  },
};
