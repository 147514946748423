import $request from "@/https/axios";
import $axios from "axios";

const $v3 = 'https://backend.hycatrade.com/wp-json/rimplenet/v3/'
const nellalink = 'https://backend.hycatrade.com/wp-json/nellalink/v2'

const appDomain = {
  // Auth
  async register(credentials) {
    return $request
      .post("users", credentials)
      .then((response) => response.data);
  },

  async sendToken(params) {
    return $request
      .get(`/verify-email-address?email_address=${params}&fire_email=true`)
      .then((response) => response.data);
  },

  async verifyToken(credentials) {
    return $request
      .post("/verify-email-address", credentials)
      .then((response) => response.data);
  },

  //   Get Wallets
  async getWallets(params, body) {
    return $request
      .get(
        `user-wallet-balance/multi?wallet_id=${body}&formatted=yes&user_id=${params}`
      )
      .then((response) => response.data);
  },

  //   Get Transactions
  async getTransactions(params, page) {
    return $axios
      .get(`${$v3}transactions?user_id=${params}&page_no=${page}`)
      .then((response) => response.data);
  },

  // Upload KYC 
  async uploadKYC(credentials, userID) {
    return $axios
      .put(`${nellalink}/smart-meta-manager/user/${userID}`, credentials)
      .then((response) => response.data);
  }
};

export default appDomain;
