<template>
  <div class="card">
    <div class="text-center">
      <i class="checkmark">✓</i>
    </div>
    <h1 class="text-center font-semibold">KYC Submitted Successfully</h1>
    <p class="text-center w-7/12 mx-auto">
      <!-- We received your KYC submission;<br />
      we'll be in touch shortly! -->
      {{ message }}
    </p>
  </div>
</template>

<script>
export default {
  beforeMount() {
    console.log(this.userMeta, "user Meta");
    // if (this.userMeta.user_kyc_level_2_status == "pending") {
    //   this.$router.push("/kyc-submitted");
    // }
  },

  computed: {
    message() {
      let display_message = "";
      if (
        this.userMeta.user_kyc_level_2_status == "approved" &&
        this.userMeta.user_kyc_level == 2
      ) {
        display_message = "You have been verified on KYC Level 2";
      } else if (this.userMeta.user_kyc_level_2_status == "pending") {
        display_message =
          "Your KYC Level 2 is pending and awaiting approval, please check back later";
      }
      return display_message;
      // else {
      // The user can proceed to submit his KYC Level 2
      //       if(user_kyc_level_2_status =="rejected"){
      //        display note: Your Last KYC Submission was rejected - ##The note admin dropped##
      // }}
    },
    user() {
      return this.$store.getters["auth/getUser"];
    },

    userMeta() {
      return this.$store.getters["auth/getUserMeta"];
    },
  },
};
</script>

<style>
h1 {
  color: green;
  /* font-family: "Nunito Sans", "Helvetica Neue", sans-serif; */
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
p {
  color: #404f5e;
  /* font-family: "Nunito Sans", "Helvetica Neue", sans-serif; */
  font-size: 20px;
  margin: 0;
}
i {
  color: green;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.card {
  background: white;
  padding: 40px;
  border-radius: 4px;
  /* box-shadow: 0 2px 3px #c8d0d8; */
  display: inline-block;
  margin: 0 auto;
}
</style>
